import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import "./AddAttendance.scss";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const AddAttendanceForm = ({ studentInfo, onAttendanceUpdate, attendanceLabel }) => {
  
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const teacherID = JSON.parse(localStorage.getItem("userInfo"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const initialAttendanceStatuses = Array(studentInfo.length).fill("P");
  const [attendanceStatuses, setAttendanceStatuses] = useState(
    initialAttendanceStatuses
  );
  const activeFyr = localStorage.getItem("AY");
  const nToken = localStorage.getItem("nToken");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  useEffect(() => {
    if (studentInfo && studentInfo.length > 0) {
      setCurrentSlideIndex(studentInfo[0].id);
    }
  }, [studentInfo]);

  const handleSlideChange = (studentId, index) => {
    setCurrentSlideIndex(studentId);
    setCarouselIndex(index);
  };

  const handleAttendanceChange = (event) => {
    const { value } = event.target;
    setAttendanceStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[currentSlideIndex] = value;
      return newStatuses;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedDate = selectedDate.$D;
    const formattedMonth = selectedDate.$M + 1;

    const apiUrl = `${process.env.REACT_APP_nodewsPrefix}/addAttendance`;

    try {
      const studentIds = studentInfo.map((stdInfo) => stdInfo.id);

      const studentAttendance = studentIds.map(
        (id) => attendanceStatuses[id] || "P"
      );

      const response = await axios.post(
        apiUrl,
        {
          admission_no: studentIds,
          class: studentInfo[0].class,
          section: studentInfo[0].section,
          financial_year: activeFyr,
          added_by: teacherID[0].id,
          day: formattedDate,
          month: formattedMonth,
          attendance: studentAttendance,
          schoolcode: userinfo[0].institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );

      setOpenSnackbar(true);
      setIsDialogOpen(false);
      onAttendanceUpdate(response.data);
      // setSelectedDate("");
    } catch (error) {
      console.error("Error adding attendance:", error.message);
    }
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };


  const attendanceLabelMarking =
    Array.isArray(attendanceLabel?.marking) &&
    attendanceLabel?.marking.length > 0;
  return (
    <Box>
      <Button
        type="submit"
        color="secondaryColor"
        variant="contained"
        onClick={openDialog}
        className="btn"
      >
        Mark Attendance
      </Button>
      <Snackbar
        open={openSnackbar}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarContent
          sx={{ backgroundColor: "greenColor.color" }}
          message="Attendance added successfully!"
          action={
            <Button color="inherit" size="small" onClick={handleSnackbarClose}>
              OK
            </Button>
          }
        />
      </Snackbar>

      <CustomDialog isOpen={isDialogOpen} width={"sm"}  onClose={closeDialog}>
        <Box className="attendanceCard">
          <Box className="carouselContainer">
            <form onSubmit={handleSubmit}>
              {attendanceLabelMarking ? (
                <>
                  <Box
                    py={2}
                    px={3}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"end"}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Select Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        format="DD/MM/YYYY"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Carousel
                    navButtonsAlwaysVisible
                    autoPlay={false}
                    indicators={false}
                    onChange={(index) =>
                      handleSlideChange(studentInfo[index].id, index)
                    }
                  >
                    {studentInfo.map((stdInfo, index) => (
                      <Box key={stdInfo.id} className="carouselContent">
                        <Box className="stdDetails">
                          <Box className="stdProfile">
                            <img
                              src={stdInfo.profileimageurl}
                              alt="profile-img"
                              width={"100%"}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Name: {stdInfo.fullname}
                            </Typography>
                            <Typography variant="p" sx={{ fontWeight: "bold" }}>
                              ID: {stdInfo.id}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          mt={2}
                          className="attendanceOptionBlock"
                          textAlign="center"
                        >
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="attendance-status"
                              name="attendance-status"
                              value={attendanceStatuses[currentSlideIndex]}
                              defaultValue="P"
                              onChange={handleAttendanceChange}
                              className="attendanceOption"
                            >
                              {attendanceLabelMarking ? (
                                attendanceLabel.marking.map((val, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={val.charAt(0)}
                                    control={<Radio />}
                                    label={val.charAt(0)}
                                    className={`select ${val.toLowerCase()}`}
                                  />
                                ))
                              ) : (
                                <p>No labels available</p>
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box>
                    ))}
                  </Carousel>
                  <Box className="label-tile " justifyContent={"center"}>
                    {attendanceLabel.marking.map((val, index) => (
                      <Typography
                        className={`label-data ${val.charAt(0).toUpperCase()}`}
                      >
                        {val}
                      </Typography>
                    ))}
                  </Box>
                  {carouselIndex === studentInfo.length - 1 ? (
                    <Box my={3} textAlign="center">
                      <Button
                        type="submit"
                        variant="contained"
                        title={`${
                          selectedDate
                            ? ""
                            : "Please select date to enter attendance"
                        }`}
                        className={`${
                          selectedDate
                            ? "btn primaryBtn"
                            : "btn primaryBtn notSelected"
                        }`}
                      >
                        Submit Attendance
                      </Button>
                    </Box>
                  ) : (
                    <Box my={3} textAlign="center">
                      <Button
                        onClick={closeDialog}
                        variant="contained"
                        className="smallPrimaryBtn"
                      >
                        close
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
              
                <Typography p={4}>
                  Attendance configuration is pending. Please contact your administrator for assistance.
                </Typography>
              )}
            </form>
          </Box>
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default AddAttendanceForm;
