import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Calendar } from "react-calendar";
import AttendReport from "./AttendReport";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const AttendanceCalendar = ({
  attendanceData = {},
  stdId,
  stdName,
  attendanceLabel,
}) => {
  const [date, setDate] = useState(new Date());
  const [attendanceReport, setAttendanceReport] = useState(null);
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const statusShortForm = attendanceLabel.marking.reduce((acc, status) => {
    acc[status] = status[0];
    return acc;
  }, {});

  useEffect(() => {
    const calculateAttendanceReport = () => {
      const currentMonthKey = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(date);

      const currentMonthData = attendanceData[stdId]?.[currentMonthKey] ?? [];

      const counts = attendanceLabel.marking.map(
        (status) =>
          currentMonthData.filter((data) => data === statusShortForm[status])
            .length
      );

      const data = {
        labels: attendanceLabel.marking,
        datasets: [
          {
            data: counts,
          },
        ],
      };
      setAttendanceReport(data);
    };

    calculateAttendanceReport();
  }, [attendanceData, stdId, date, attendanceLabel]);

  const formatAttendanceData = (date) => {
    const day = date.getDate();
    const currentMonthKey = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(date);
    const currentMonthData = attendanceData[stdId]?.[currentMonthKey];

    if (currentMonthData && currentMonthData[day - 1] !== undefined) {
      return currentMonthData[day - 1];
    }

    return null;
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const dayAttendance = formatAttendanceData(date);
      return dayAttendance ? (
        <CustomTile
          key={date.toString()}
          date={date}
          attendanceStatus={dayAttendance}
        />
      ) : null;
    }
    return null;
  };

  const CustomTile = ({ date, attendanceStatus }) => {
    const buttonClass = Object.keys(statusShortForm).find(
      (key) => statusShortForm[key] === attendanceStatus
    );

    return (
      <Box className={`calendar-day-button custom-tile ${buttonClass}`}>
        <span className="day-number">{date.getDate()}</span>
        <span className="attendance-status">{attendanceStatus}</span>
      </Box>
    );
  };

  const handleCalendarChange = (newDate) => {
    setDate(newDate);
  };

  const isPrevDisabled =
    date.getMonth() === 0 && date.getFullYear() === currentYear;
  const isNextDisabled =
    date.getMonth() === currentMonth && date.getFullYear() === currentYear;
  const handlePrevMonth = () => {
    if (!isPrevDisabled) {
      const newMonth = date.getMonth() - 1;
      const newYear = date.getFullYear();
      const newDate =
        newMonth < 0
          ? new Date(newYear - 1, 11, date.getDate())
          : new Date(newYear, newMonth, date.getDate());
      setDate(newDate);
    }
  };

  const handleNextMonth = () => {
    if (!isNextDisabled) {
      const newMonth = date.getMonth() + 1;
      const newYear = date.getFullYear();
      const newDate =
        newMonth > 11
          ? new Date(newYear + 1, 0, date.getDate())
          : new Date(newYear, newMonth, date.getDate());
      setDate(newDate);
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mb={1}
        className="monthDetail"
      >
        <Button onClick={handlePrevMonth} title="Previous Month" disabled={isPrevDisabled}>
          <IoChevronBack size={20} />
        </Button>
        <Typography variant="h5">
          <b>{stdName}'s Record</b>
        </Typography>

        <Button onClick={handleNextMonth} title="Next Month"  disabled={isNextDisabled}>
          <IoChevronForward size={20} />
        </Button>
      </Box>
      <Grid container spacing={2} alignItems={"center"} minHeight={"500px"}>
        <Grid item md={5}>
          <Calendar
            showNeighboringMonth={false}
            onChange={(newDate) => handleCalendarChange(newDate)}
            value={date}
            tileContent={tileContent}
          />
          <Box className="label-tile">
            {attendanceLabel.marking.map((status, idx) => (
              <Typography
                key={idx}
                className={`${statusShortForm[status]} label-data`}
              >
                {status}
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item md={7}>
          {attendanceReport && (
            <AttendReport
              attendanceReport={attendanceReport}
              echartsKey={0}
              attendanceLabel={attendanceLabel.marking}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AttendanceCalendar;
