import CreateModuleForm from "../../_Components/Common/ReportCard/CreateModuleForm";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";

const Module = () => {
  const { permissions } = usePermissions();
  return <>
  {permissions.module?.write && permissions.module?.read && <CreateModuleForm />}
  
  {permissions.module?.write && !permissions.module?.read &&  "for teacher"}
  {!permissions.module?.write && permissions.module?.read &&  "for student"}

  </>;
};

export default Module;
