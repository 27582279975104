import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import logo from "../../images/logo.png";
import Loader from "../../_Components/Utils/loader/Loader";
import "./login.scss";
import { usePermissions } from "../Permission/PermissionsContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IoCloseCircleOutline } from "react-icons/io5";

const Login = ({ loginBtnClick, closeDialog, disableOption }) => {
  const [user, setUser] = useState({ username: "", password: "" });
  const [showPassword, setShowPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { updateUser } = usePermissions();

  const onLoginClicked = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const tokenURL = `${process.env.REACT_APP_wsPrefix}/login/token.php?username=${user.username}&password=${user.password}&service=ws`;
      const response = await axios.get(tokenURL);

      if (response.data.token !== undefined) {
        localStorage.setItem("token", response.data.token);
        const isAuthorized = await authorisation(
          user.username,
          user.password,
          response.data.token
        );
        if (isAuthorized) {
          navigate("/dashboard");
        } else {
          setIsLoading(false);
          setError("Authorization failed. Please try again.");
        }
      } else if (
        response.data.errorcode == "invalidlogin" ||
        response.data.errorcode == "generalexceptionmessage"
      ) {
        setError("Authorization failed. Please try again.");
      } else {
        setIsLoading(false);
        setError("Authorization failed. Please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert(error.message);
      setIsLoading(false);
    }
    loginBtnClick(isLoading);
  };

  const authorisation = async (uname, pass, token) => {
    try {
      let data = { uname, pass };
      let response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/login`,
        data
      );

      let custresponse = await axios.get(
        `${process.env.REACT_APP_wsPrefix}/customloginsso/index.php?token-genrate=1&username=${uname}&password=${pass}`
      );

      const nToken = response.data;
      localStorage.setItem("nToken", response.data);
      localStorage.setItem("mToken", custresponse.data.token);

      const isUserFetched = await fetchUser(token, nToken);
      return isUserFetched;
    } catch (error) {
      console.error("Error during authorisation:", error);
      setIsLoading(false);
      alert("An error occurred during login.");
      throw new Error("Authorization failed.");
    }
  };

  const fetchUser = async (token, nToken) => {
    try {
      // Fetch user site info
      const siteInfoUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_webservice_get_site_info&moodlewsrestformat=json`;
      const siteInfoResponse = await axios.get(siteInfoUrl);
      const userId = siteInfoResponse.data.userid;
      const usernames = siteInfoResponse.data.username;

      // Fetch user details
      const userInfoUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_user_get_users_by_field&moodlewsrestformat=json&field=id&values[0]=${userId}`;
      const userInfoResponse = await axios.get(userInfoUrl);
      const userData = userInfoResponse.data;
      localStorage.setItem("userInfo", JSON.stringify(userData));
      console.log(userData, "userDatauserData");

      // Update user in PermissionsContext
      updateUser(userData);

      // Fetch course details
      const courseInfoUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_users_courses&moodlewsrestformat=json&userid=${userId}`;
      const courseInfoResponse = await axios.get(courseInfoUrl);
      const courseData = courseInfoResponse.data;
      localStorage.setItem("courseDetails", JSON.stringify(courseData));

      // Set Financial Year
      const financialYearUrl = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/FetchData`,
        {
          record_type: "financial_year",
          type: "aerp",
          schoolcode: userData[0]?.institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      const financialYear = financialYearUrl.data.records;
      const financeyear = financialYear.filter(
        (filterData) => filterData.status === 1
      );
      if (financeyear.length > 0) {
        const idToStore = financeyear[0].id;
        localStorage.setItem("AY", idToStore.toString());
      } else {
        console.error("No financial year with status 1 found");
      }

      // Set class and sec
      let resp = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/getUserInfo`,
        {
          username: usernames,
        }
      );
      localStorage.setItem("classInfo", JSON.stringify(resp.data));
      return true;
    } catch (error) {
      console.error("Error during fetching user data:", error);
      setIsLoading(false);
      alert("An error occurred during fetching user data.");
      return false;
    }
  };

  const handleChange = (e) => {
    setError(null);
    setIsLoading(false);
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const forgotHandler = () => {
    window.location.href = "/user-forgot-password";
  };

  const passwordHandler = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      className="loginForm"
      onClick={(e) => e.stopPropagation()}
      px={4}
      py={2}
    >
      <Box className="loginLogo" sx={{ textAlign: "center" }}>
        <img src={logo} alt="Ratna Sagar logo" />
      </Box>
      {error != null ? (
        <Alert severity="error" onClose={() => {}}>
          {error}
        </Alert>
      ) : (
        ""
      )}
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        disabled={isLoading}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[900],
          opacity: isLoading ? 0.4 : 1,
        }}
      >
        <IoCloseCircleOutline size={40} color="#9fa2ab" />
      </IconButton>
      <form onSubmit={onLoginClicked}>
        <Box className="loginDetails">
          <TextField
            className="loginFields"
            id="username"
            label="Name"
            type="text"
            name="username"
            onChange={handleChange}
            color="warning"
            fullWidth
            required
            disabled={isLoading && !error}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              className="loginFields"
              id="userpassword"
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={handleChange}
              color="warning"
              fullWidth
              required
              disabled={isLoading && !error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={passwordHandler}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Box>
        <Button
          disabled={isLoading}
          className={isLoading ? "loaderArea" : ""}
          type="submit"
          sx={{ py: 1.5, minHeight: "54px", textAlign: "center" }}
          variant="contained"
          color="warning"
          fullWidth
        >
          {isLoading && !error ? <Loader /> : "Login"}
        </Button>
        <Box style={{ textAlign: "center" }} pt={2}>
          {
            <Button
              disabled={isLoading}
              variant="text"
              sx={{ color: "linkTxt.color", textAlign: "center" }}
              onClick={forgotHandler}
            >
              Forgot Password?
            </Button>
          }
        </Box>
      </form>
    </Box>
  );
};

export default Login;
