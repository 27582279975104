import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";

const TimeTableView = ({
  timeTblData,
  daysOfWeek,
  dayOfWeek,
  permissions,
  teacherPeriod,
  holiday,
}) => {
  const order = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const periods = Array.from(
    new Set(
      Object.keys(timeTblData).flatMap((period) =>
        period === "period_0" ? "Recess" : period
      )
    )
  );

  const weekdays = Array.from(
    new Set(
      Object.values(timeTblData)
        .flatMap((day) => Object.keys(day))
        .filter((d) => d.toLowerCase() !== "recess")
    )
  );
  let holidayString = holiday || "";
  let combinedArray = [...weekdays, ...holidayString.split(",")];
  const days = combinedArray.sort((a, b) => {
    return order.indexOf(a) - order.indexOf(b);
  });
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };
  return (
    <TableContainer className="teacherTimeTblWrapper">
      <Scrollbar style={{ width: "100%", height: `calc(72vh - 20px)` }}>
        {console.log(timeTblData, "timeTblData")}
        {isEmpty(timeTblData) ? (
          <Typography
            variant="h6"
            mt={1}
            className="mssgCard"
          >
            The timetable has not been assigned. Please contact your administrator for further assistance.
          </Typography>
        ) : (
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="timeTbl"
          >
            <TableHead>
              <TableRow>
                <TableCell>Day/Period</TableCell>
                {permissions.timetable?.write
                  ? teacherPeriod.map((data) => {
                      return (
                        <TableCell
                          key={data}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <b>{data.replace(/_/g, " ")}</b>
                        </TableCell>
                      );
                    })
                  : permissions.timetable?.read
                  ? periods.map((data) => {
                      return (
                        <TableCell
                          key={data}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <b>{data.replace(/_/g, " ")}</b>
                        </TableCell>
                      );
                    })
                  : ""}
              </TableRow>
            </TableHead>

            <TableBody>
              {days.map((day) => (
                <TableRow key={day}>
                  <TableCell
                    className="daysName"
                    sx={{ border: "0", padding: "5px", paddingLeft: "20px" }}
                  >
                    <b>{day.toLocaleUpperCase()}</b>
                  </TableCell>
                  {periods.map((period) => (
                    <TableCell
                      className={period}
                      key={period}
                      sx={{ border: "0", padding: "5px" }}
                    >
                      {holiday?.includes(day) ? (
                        <Typography className="blueShade weekBox periodSubj">
                          Holiday
                        </Typography>
                      ) : timeTblData[period] && timeTblData[period][day] ? (
                        <div className="teacherTimeTblInfo">
                          <Card
                            className={`${
                              day?.toLowerCase() ===
                              daysOfWeek[dayOfWeek - 1]?.toLowerCase()
                                ? "yellowShade weekBox"
                                : "periodSubj blueShade weekBox"
                            }`}
                          >
                            <Typography
                              fontSize={16}
                              fontWeight={500}
                              className="subjTitle"
                            >
                              {timeTblData[period][day]?.subject_name ||
                                "No Subject"}
                            </Typography>

                            <Typography variant="body" className="teacherTitle">
                              {permissions.timetable?.write
                                ? timeTblData[period][day]?.class_section
                                : permissions.timetable?.read
                                ? timeTblData[period][day]?.teacher_name
                                : "--"}
                            </Typography>
                          </Card>
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Scrollbar>
    </TableContainer>
  );
};

export default TimeTableView;
