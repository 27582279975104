import React from "react";

const UploadImage = ({ mssg }) => {
  return (
    <img
      src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/backBtn.png`}
      alt="uploadImg"
      width={52}
      title={mssg}
      style={{
        rotate: "90deg",
        border: "2px solid #b6d2fd",
        borderRadius: "100%",
      }}
    />
  );
};

const DownloadImage = ({ mssg }) => {
  return (
    <img
      src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/backBtn.png`}
      alt="downloadImg"
      width={52}
      title={mssg}
      style={{
        rotate: "-90deg",
        border: "2px solid #b6d2fd",
        borderRadius: "100%",
      }}
    />
  );
};

export { UploadImage, DownloadImage };
