import { Box, MenuItem, MenuList } from "@mui/material";
import "./QuickLinks.scss";
import QuickLinkItem from "./QuickLinkItem";
import axios from "axios";
import { forwardRef, useEffect, useState } from "react";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const QuickLinks = forwardRef((props, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const userInfoData = localStorage.getItem("userInfo");
  const userInfo = JSON.parse(userInfoData);
  const course = localStorage.getItem("courseDetails");
  const courserInfo = JSON.parse(course);
  const [assigncount, setAssignCount] = useState([]);
  const [customModule, setCustomModule] = useState([]);
  const [viewAssessment, setviewAssessment] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const isDashboardScreen = location.pathname === "/dashboard";
  const reduxData = useSelector((state) => state.items);
  const teacher = userInfo[0].department.toLowerCase() === "pedagogy";

  useEffect(() => {
    const getUserClass = async () => {
      if (permissions.classes?.write) {
        const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByTeacherCourse`;
        axios
          .post(
            v_assessmentURL,
            {
              userid: userInfo[0].id,
              schoolcode: userInfo[0].institution,
              show_count: 1,
            },
            {
              headers: {
                Authorization: `Bearer ${nToken}`,
              },
            }
          )
          .then((response) => {
            setAssignCount(response.data.count);
            setviewAssessment(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      if (permissions.courses?.write) {
        const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByStudentCourse`;
        axios
          .post(
            v_assessmentURL,
            {
              userid: userInfo[0]?.id,
              classid: JSON.parse(localStorage.getItem("classInfo"))?.classid,
              schoolcode: userInfo[0]?.institution,
              show_count: 1,
            },
            {
              headers: {
                Authorization: `Bearer ${nToken}`,
              },
            }
          )
          .then((response) => {
            setAssignCount(response.data.count);
            setviewAssessment(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    };
    getUserClass();

    const fetchConfig = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept`,
          {
            department: userInfo[0].department,
            schoolcode: userInfo[0]?.institution,
          }
        );

        const customModules = response.data.config.custommodules;
        const departmentKey = userInfo[0].department.toLowerCase();
        const departmentConfig = customModules?.[departmentKey] || {};

        setCustomModule(departmentConfig);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);

  const classTeacher = reduxData.classSecData.find(
    (item) => item.is_classteacher === 1
  );
  const handleMenuItemClick = (event) => {
    navigate("/Assignments", { state: { viewAssessment } });
    props.handleClose(event);
  };

  const handleCertificate = (event) => {
    permissions.certificate?.read &&
      permissions.certificate?.write &&
      navigate("/addCertificate");
    props.handleClose(event);

    permissions.certificate?.read &&
      !permissions.certificate?.write &&
      navigate("/stdCertificate");
    props.handleClose(event);
  };

  const handleTimtableUrl = (event) => {
    navigate("/timetable");
    props.handleClose(event);
  };

  const handleDashboard = (event) => {
    navigate(isDashboardScreen ? "/" : "/dashboard");
    props.handleClose(event);
  };

  const handleAttendance = (event) => {
    navigate("/attendance");
    props.handleClose(event);
  };

  const handleCalendar = (event) => {
    navigate("/calendar");
    props.handleClose(event);
  };
  const handleDatesheet = (event) => {
    navigate("/dateSheet");
    props.handleClose(event);
  };
  const handleNotes = (event) => {
    navigate("/notes");
    props.handleClose(event);
  };
  const handleModule = (event) => {
    navigate("/module");
    props.handleClose(event);
  };

  const classSection = classTeacher ? classTeacher.classsection : null;
  return (
    <>
      {(permissions.dashboard?.read || permissions.dashboard?.write) && (
        <MenuItem className="quickLinkItem" onClick={handleDashboard}>
          {isDashboardScreen ? (
            <QuickLinkItem
              text="Home"
              icon="home.png"
              permissionName="dashboard"
            />
          ) : (
            <QuickLinkItem
              text="Dashboard"
              icon="dashboard.png"
              permissionName="dashboard"
            />
          )}
        </MenuItem>
      )}
      {console.log(assigncount)}
      {(permissions.assignment?.read || permissions.assignment?.write) && (
        <MenuItem className="quickLinkItem" onClick={handleMenuItemClick}>
          <QuickLinkItem
            text="Assignments"
            icon="assignment.png"
            permissionName="assignment"
            assignmentCounts={assigncount}
            viewAssessment={viewAssessment}
          />
        </MenuItem>
      )}
      {(permissions.attendance?.read || permissions.attendance?.write) && (
        <MenuItem className="quickLinkItem" onClick={handleAttendance}>
          <QuickLinkItem
            text="Attendance"
            icon="attendance.png"
            permissionName="attendance"
            classDetail={classSection}
          />
        </MenuItem>
      )}
      {(permissions.calendar?.read || permissions.calendar?.write) && (
        <MenuItem className="quickLinkItem" onClick={handleCalendar}>
          <QuickLinkItem
            text="Calendar"
            icon="calendar.png"
            permissionName="calendar"
          />
        </MenuItem>
      )}
      {(permissions.certificate?.read || permissions.certificate?.write) && (
        <MenuItem className="quickLinkItem" onClick={handleCertificate}>
          <QuickLinkItem
            text="Certificate"
            icon="certificate.png"
            permissionName="certificate"
          />
        </MenuItem>
      )}
      {(permissions.dateSheet?.read || permissions.dateSheet?.write) && (
        <MenuItem className="quickLinkItem" onClick={handleDatesheet}>
          <QuickLinkItem
            text="Date Sheet"
            icon="datesheet.png"
            permissionName="dateSheet"
          />
        </MenuItem>
      )}
      {(permissions.notes?.read || permissions.notes?.write) && (
        <MenuItem className="quickLinkItem" onClick={handleNotes}>
          <QuickLinkItem text="Notes" icon="notes.png" permissionName="notes" />
        </MenuItem>
      )}
      {permissions.module?.read && (
        <>
          {Object.keys(customModule).map((departmentKey, index) => (
            <MenuItem
              className="quickLinkItem"
              onClick={handleModule}
              key={index}
            >
              <QuickLinkItem
                text={departmentKey}
                icon="report.png"
                permissionName="module"
              />
            </MenuItem>
          ))}
        </>
      )}
      {(permissions.timetable?.read || permissions.timetable?.write) && (
        <MenuItem className="quickLinkItem" onClick={handleTimtableUrl}>
          <QuickLinkItem
            text="Timetable"
            icon="timetable.png"
            permissionName="timetable"
          />
        </MenuItem>
      )}
    </>
  );
});

export default QuickLinks;
