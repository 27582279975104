import { Box, Typography } from "@mui/material";
import { IoIosLock } from "react-icons/io";

const AssetContent = ({
  moduleValue,
  presentAssignment,
  imgName,
  checkActiveAssignments,
  clickOpenForm
}) => {
  
  return (
    <Box className="assetsBoxes" onClick={clickOpenForm}>
      {presentAssignment > 0 && !checkActiveAssignments && (
        <Box className="lockedBg">
          <IoIosLock size={30} />
        </Box>
      )}
      <Box
        className="titleFace"
        sx={{
          color: "textsColor.secColor",
        }}
      >
        {moduleValue.name}
      </Box>
      <Box className={`imgBox`}>
        <img
          src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/courseIcons/${imgName}.png`}
          className="assetOverlayImg"
          alt={imgName}
        />
      </Box>
      <Box className={`overlayBox`}>
        <Box className="topIcon">{moduleValue.name}</Box>
        {presentAssignment > 0 && !checkActiveAssignments && (
          <Typography className="dataInfo">
            Locked until the assignment is completed.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AssetContent;
