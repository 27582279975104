import { Box, Card, Container, FormControl, Grid, Input, InputLabel, Typography } from "@mui/material"
import BackButton from "../../../_Components/Common/BackBtn/BackBtn"
import { useSearchParams } from "react-router-dom";

function CreateQuiz() {
    const [SearchParams] = useSearchParams();


    const courseID = SearchParams.get("courseid");
    const section = SearchParams.get("section");

    console.log(courseID, section, "ind77");

    return (
        <div className="dashboardMain">
            <Container maxWidth="xl">
                <Grid item md={12} sm={12}>
                    <Card
                        maxWidth="xl"
                        sx={{
                            mb: 4,
                            backgroundColor: "cardColor.main",
                            color: "cardColor.contrast",
                        }}
                        elevation={5}
                        className="customCard"
                    >
                        <Typography
                            className="title"
                            variant="h5"
                            px={2}
                            py={1}
                            sx={{ borderColor: "borderLine.main" }}
                        >
                            <Box className="titleHead">
                                <BackButton />
                                <Box>
                                    Create Assesment
                                </Box>
                            </Box>
                        </Typography>

                        <Box className="customCardContent" p={2}>
                            <Grid container spacing={3}>
                                <Grid item md={6} sm={12} >
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="assessmentname">Assessment Name</InputLabel>
                                        <Input
                                            labelId="assessmentname"
                                            id="formcode-select"
                                            name="assessmentname"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="assessmentdescription">Assessment Description</InputLabel>
                                        <Input
                                            labelId="assessmentdescription"
                                            id="formcode-select"
                                            name="assessmentdescription"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>



                        </Box>
                    </Card>
                </Grid>
            </Container>
        </div>
    )
}

export default CreateQuiz
