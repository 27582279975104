import React, { useEffect, useState } from 'react';
import {
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox, FormGroup, FormControlLabel, FormLabel
} from '@mui/material';
import axios from 'axios';
import MainCard from '../Cards/MainCard';
import { PrimaryBgBtn } from '../Buttons/CustomBtns';
function ViewModuleTable(props) {
    console.log(props);
    const [data, setData] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [selectedData, setSelectedData] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [editColumnName, setEditColumnName] = useState([]);
    const [permissions, setPermissions] = useState();
    const [newSection, setNewSection] = useState({
        role: "",
        department: "",
        section: "",
        additionalInfo: "",
        read: false,
        write: false,
        canAccess: false,
    });
    const [permissionss, setPermissionss] = useState({
        read: false,
        write: false,
        canAccess: false,
    });
    const [open, setOpen] = useState(false);
    const handleChange = (event) => {
        const title = event.target.value;
        setSelectedTitle(title);
        const selected = data.find((item) => item.title === title);
        setSelectedData(selected);
    };
    const nToken = localStorage.getItem("nToken");
    const getuserInfo = localStorage.getItem("userInfo");
    const getfinancialYear = localStorage.getItem("financialYear");
    const financialYear = JSON.parse(getfinancialYear);
    const userInfo = JSON.parse(getuserInfo);
    const schoolcode = userInfo[0].institution;
    useEffect(() => {
        const url = `${process.env.REACT_APP_nodewsPrefix}/viewDynamicTable?schoolcode=${schoolcode}`;
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${nToken}`,
            },
        }).then((response) => {
            setData(response.data.module_data);
        });
    }, [schoolcode, nToken]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_nodewsPrefix}/getSchoolConfigData`,
                    {
                        schoolcode: schoolcode,
                    }
                );
                setPermissions(response.data.info);
            } catch (error) {
                console.error("Error fetching permissions:", error);
            }
        };

        fetchData();
    }, [schoolcode])
    const handleEditOpen = () => {
        props.editData({ ...selectedData, "edit": 1 })
        setSelectedTitle('');
        setSelectedData(null);
    };
    const handleDeletePopup = () => {
        setEditOpen(true)
    }
    const handleDelete = () => {
        if (!selectedTitle) return;
        const url = `${process.env.REACT_APP_nodewsPrefix}/dropTableDynamic?module=${selectedTitle}&schoolcode=${schoolcode}`;
        axios.delete(url, {
            headers: {
                Authorization: `Bearer ${nToken}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    const updatedData = data.filter((item) => item.title !== selectedTitle);
                    setData(updatedData);
                    setSelectedTitle('');
                    setSelectedData(null);
                }
            })
            .catch((error) => {
                console.error("Error deleting the data: ", error);
            });
    };

    const handleAddSection = async () => {
        console.log("rajji", permissions);

        setPermissions((prevPermissions) => {
            const { role, department, section, } = newSection;

            const newPermissions = {
                ...prevPermissions,
                [department]: {
                    ...(prevPermissions[department] || {}),
                    [section]: {
                        ...(prevPermissions[department]?.[section] || {}),
                        read: permissionss.read,
                        write: permissionss.write,
                        canAccess: permissionss.canAccess,
                    },
                },
            };
            console.log(newPermissions);

            return newPermissions;
        });
        try {
            const datas = JSON.stringify(permissions);
            await axios.post(
                `${process.env.REACT_APP_nodewsPrefix}/updateConfigData`,
                {
                    permission: datas,
                    schoolcode: schoolcode,
                    type: "update",
                },
                {
                    headers: {
                        Authorization: `Bearer ${nToken}`,
                    },
                }
            );
            alert("Updated Successfully");
        } catch (error) {
            console.error("Error updating permissions:", error);
        }
        setOpen(false);
        setNewSection({
            role: "",
            department: "",
            section: "",
            additionalInfo: "",
            read: false,
            write: false,
            canAccess: false,
        });
    };


    const handleChanges = (event) => {

        setPermissionss({
            ...permissionss,
            [event.target.name]: event.target.checked,
        });


    };
   
    return (
        <MainCard cardTitle="" customClass="" mt={2}>
            <Box sx={{ p: 3 }}>
                <FormControl fullWidth>
                    <InputLabel id="select-label">Select Module</InputLabel>
                    <Select
                        labelId="select-label"
                        value={selectedTitle}
                        onChange={handleChange}
                        label="Select Title"
                    >
                        {data.map((item) => (
                            <MenuItem key={item.title} value={item.title}>
                                {item.title.replace("_"," ")}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedData && (
                    <>
                        <Typography variant="h6" sx={{ mt: 3 }}>
                            {selectedData.title.replace("_"," ")}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            {selectedData.description}
                        </Typography>
                        <PrimaryBgBtn onClick={handleEditOpen} sx={{ mb: 2 }}>
                            Edit
                        </PrimaryBgBtn>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleDeletePopup}
                            sx={{ mb: 2, ml: 2 }}
                        >
                            Delete
                        </Button>
                        <PrimaryBgBtn onClick={() => setOpen(true)} sx={{ ml: 5, mb: 2 }}>
                           Set Permission
                        </PrimaryBgBtn>
                        <TableContainer component={Paper} sx={{ mt: 3 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {selectedData.customColumn.map((col) => (
                                            <TableCell key={col.id}>{col.section_data}</TableCell>
                                        ))}
                                        {selectedData.columnName.map((col, index) => (
                                            <TableCell key={index}>{col}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
                            <DialogTitle>Delete Modules</DialogTitle>
                            <DialogContent>
                                Are you sure you want to delete this Modules?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setEditOpen(false)}>Cancel</Button>
                                <Button onClick={handleDelete} variant="contained">
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Add Department and Section</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Department"
                            value={newSection.department}
                            onChange={(e) =>
                                setNewSection({
                                    ...newSection,
                                    department: e.target.value,
                                })
                            }
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Section"
                            value={newSection.section}
                            onChange={(e) =>
                                setNewSection({ ...newSection, section: e.target.value })
                            }
                            fullWidth
                            margin="normal"
                        />
                        <FormLabel component="legend">Permissions</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissionss.read}
                                        onChange={handleChanges}
                                        name="read"
                                        color="primary"
                                    />
                                }
                                label="Read"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissionss.write}
                                        onChange={handleChanges}
                                        name="write"
                                        color="primary"
                                    />
                                }
                                label="Write"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={permissionss.canAccess}
                                        onChange={handleChanges}
                                        name="canAccess"
                                        color="primary"
                                    />
                                }
                                label="Can Access"
                            />
                        </FormGroup>
                    </DialogContent>



                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button onClick={handleAddSection}>Add Section</Button>
                    </DialogActions>
                </Dialog>
            </Box>

        </MainCard>
    );
}
export default ViewModuleTable;
